@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: 'Raleway', sans-serif;
    background-color: #f1f5f9;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 0.6s forwards;
}

.blur-md {
    backdrop-filter: blur(3px);
}

@keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

.animate-blink {
    animation: blink 0.6s infinite;
}